import React from "react"
import { graphql } from "gatsby"
import { Layout, FeaturedMedia, Seo } from "../components"

const Page = (props) => {
  const {
    data: { page },
    pageContext: { schema }
  } = props
  const { title, content, featuredImage, databaseId } = page
  const { head } = schema

  return (
    <Layout hasTopBtn>
      <Seo pageData={page} head={head} />
      <article className={`post-${databaseId} container`} id={`post-${databaseId}`}>
        {title ?
          <header>
            <div>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </header>
        : null}

        {featuredImage ?
          <FeaturedMedia image={featuredImage} />
        : null}
        <div id="contenido-full" dangerouslySetInnerHTML={{ __html: content }} />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      uri
      modified
      date
      content
      commentCount
      author {
        node {
          name
          uri
          description
          avatar {
            url
          }
        }
      }
    }
  }
`

export default Page;